import { createReducer, createAction } from '@reduxjs/toolkit';

const INITIAL_STATE = {
  capacity: {
    monthInterval: {
      start: '',
      end: '',
    },
    data: [],
    roleFilters: [],
    techFilters: [],
    seniorityFilters: [],
    allocationFilter: '',
    roleActiveFilters: [],
    techActiveFilters: [],
    seniorityActiveFilters: [],
    csvData: null,
    groupingMode: 'projects',
  },
  towers: [],
  towersPaged: [],
  tower: {},
  page: 1,
  projects: [],
  people: [],
  businessUnits: [],
  schedule: {
    editingTeamMember: '',
  },
  selectedTower: '',
  selectedTowerName: '',
  selectedCostCenter: '',
  allocatedProjects: [],
  towersNoBu: 0,
  members: [],
};

export const getCapacity = createAction('GET_TOWERS_CAPACITY');
export const getTowers = createAction('GET_TOWERS');
export const getTowersPaged = createAction('GET_TOWERS_PAGED');
export const updateTowerById = createAction('GET_TOWER_BY_ID');
export const updateTowerContentById = createAction('GET_TOWER_CONTENT_BY_ID');
export const getProjects = createAction('GET_TOWERS_PROJECTS');
export const getTowerPeople = createAction('GET_TOWERS_PEOPLE');
export const getBusinessUnits = createAction('GET_TOWERS_BUSINESS_UNITS');
export const setSelectedCostCenter = createAction('SET_SELECTED_COST_CENTER');
export const getTower = createAction('GET_TOWER_DETAILS');
export const setEditingMember = createAction('SET_EDITING_MEMBER');
export const clearTower = createAction('CLEAR_TOWER');
export const updateTeamMemberPhoto = createAction('UPDATE_TEAM_MEMBER_PHOTO');
export const getAllocatedProjects = createAction('GET_ALOLCATED_PROJECTS');

export const setCapacityMonthInterval = createAction(
  'SET_CAPACITY_MONTH_INTERVAL',
);
export const setSelectedTower = createAction('SET_SELECTED_TOWER');
export const setRoleFilters = createAction('SET_CAPACITY_ROLE_FILTERS');
export const setTechFilters = createAction('SET_CAPACITY_TECH_FILTERS');
export const setAllocationFilter = createAction(
  'SET_CAPACITY_ALLOCATION_FILTER',
);
export const setSeniorityFilters = createAction(
  'SET_CAPACITY_SENIORITY_FILTERS',
);
export const setRoleActiveFilters = createAction(
  'SET_CAPACITY_ROLE_ACTIVE_FILTERS',
);
export const setTechActiveFilters = createAction(
  'SET_CAPACITY_TECH_ATIVE_FILTERS',
);
export const setSeniorityActiveFilters = createAction(
  'SET_CAPACITY_SENIORITY_ACTIVE_FILTERS',
);
export const setCapacityGroupingMode = createAction(
  'SET_CAPACITY_GROUPING_FILTER',
);
export const clearAllFilters = createAction('CLEAR_ALL_FILTERS');
export const getCapacityCSVData = createAction('SET_CAPACITY_CSV_DATA');
export const clearCsvData = createAction('CLEAR_CAPACITY_CSV_DATA');
export const setTowersNoBu = createAction('SET_TOWERS_NO_BU');
export const setMembersById = createAction('SET_MEMBERS_BY_ID');

export default createReducer(INITIAL_STATE, {
  [getCapacity.type]: (state, action) => ({
    ...state,
    capacity: { ...state.capacity, data: action.payload },
  }),
  [getTowers.type]: (state, action) => ({
    ...state,
    towers: action.payload,
  }),
  [getTowersPaged.type]: (state, action) => ({
    ...state,
    towersPaged: action.payload.towersPaged,
    page: action.payload.page ? action.payload.page : state.page,
  }),
  [updateTowerById.type]: (state, action) => ({
    ...state,
    towers: state.towers.map(tower =>
      tower._id === action.payload._id ? action.payload : tower,
    ),
  }),
  [updateTowerContentById.type]: (state, action) => ({
    ...state,
    towersPaged: {
      ...state.towersPaged,
      content: state.towersPaged.content?.map(tower =>
        tower._id === action.payload._id ? action.payload : tower,
      ),
    },
  }),
  [getAllocatedProjects.type]: (state, action) => ({
    ...state,
    allocatedProjects: action.payload,
  }),
  [getProjects.type]: (state, action) => ({
    ...state,
    projects: action.payload,
  }),
  [getTowerPeople.type]: (state, action) => ({
    ...state,
    people: action.payload,
  }),
  [getBusinessUnits.type]: (state, action) => ({
    ...state,
    businessUnits: action.payload,
  }),
  [setSelectedCostCenter.type]: (state, action) => ({
    ...state,
    selectedCostCenter: action.payload,
  }),
  [setRoleFilters.type]: (state, action) => ({
    ...state,
    capacity: { ...state.capacity, roleFilters: action.payload },
  }),
  [setTechFilters.type]: (state, action) => ({
    ...state,
    capacity: { ...state.capacity, techFilters: action.payload },
  }),
  [setSeniorityFilters.type]: (state, action) => ({
    ...state,
    capacity: { ...state.capacity, seniorityFilters: action.payload },
  }),
  [setRoleActiveFilters.type]: (state, action) => ({
    ...state,
    capacity: { ...state.capacity, roleActiveFilters: action.payload },
  }),
  [setTechActiveFilters.type]: (state, action) => ({
    ...state,
    capacity: { ...state.capacity, techActiveFilters: action.payload },
  }),
  [setSeniorityActiveFilters.type]: (state, action) => ({
    ...state,
    capacity: { ...state.capacity, seniorityActiveFilters: action.payload },
  }),
  [setAllocationFilter.type]: (state, action) => ({
    ...state,
    capacity: { ...state.capacity, allocationFilter: action.payload },
  }),
  [clearAllFilters.type]: (state, action) => ({
    ...state,
    capacity: {
      ...state.capacity,
      roleActiveFilters: [],
      techActiveFilters: [],
      seniorityActiveFilters: [],
    },
  }),
  [clearCsvData.type]: (state, action) => ({
    ...state,
    capacity: { ...state.capacity, csvData: '' },
  }),
  [getTower.type]: (state, action) => ({
    ...state,
    tower: action.payload,
  }),
  [clearTower.type]: (state, action) => ({
    ...state,
    tower: {},
    projects: [],
  }),
  [setEditingMember.type]: (state, action) => ({
    ...state,
    schedule: { editingTeamMember: action.payload },
  }),
  [setSelectedTower.type]: (state, action) => ({
    ...state,
    selectedTower: action.payload.value,
    selectedTowerName: action.payload.label,
  }),
  [setCapacityMonthInterval.type]: (state, action) => ({
    ...state,
    capacity: {
      ...state.capacity,
      monthInterval: action.payload,
    },
  }),
  [getCapacityCSVData.type]: (state, action) => {
    return {
      ...state,
      capacity: {
        ...state.capacity,
        csvData: action.payload,
      },
    };
  },
  [setCapacityGroupingMode.type]: (state, action) => {
    return {
      ...state,
      capacity: {
        ...state.capacity,
        groupingMode: action.payload,
      },
    };
  },
  [updateTeamMemberPhoto.type]: (state, action) => {
    if (state.projects) {
      return {
        ...state,
        projects: state?.projects?.map(project => {
          return {
            ...project,
            team: project?.team?.map(person => {
              if (
                person.personKey === action.payload.personKey ||
                (person.person === 'Não Definido' &&
                  action.payload.personKey === 'undefined')
              )
                return { ...person, photo: action.payload.photo };
              return person;
            }),
          };
        }),
      };
    }
    return state;
  },
  [setTowersNoBu.type]: (state, action) => ({
    ...state,
    towersNoBu: action.payload,
  }),
  [setMembersById.type]: (state, action) => ({
    ...state,
    members: action.payload,
  }),
});
