import { Select, Spin, Form } from 'antd';
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { getByTitle, get } from '~/store/fetchActions/fetchSkills';

const { Option } = Select;

export default function SelectSkills({
  defaultValueSoluter,
  selectMode,
  defaultValue,
  fieldName,
  tagRenderFunc,
  fieldLabel,
  required,
  style,
  disabled,
  testId,
  placeholder,
  isTechnology,
}) {
  const { skills } = useSelector(state => state.skills);
  const { isLoadingSkills } = useSelector(state => state.loading);
  const [searchTimeout, setSearchTimeout] = useState(0);
  const dispatch = useDispatch();

  function handleTecOrSkills(
    value,
    technology,
    action,
    searchTimeoutt,
    setSearchTimeoutt,
    dispatcher,
  ) {
    if (searchTimeoutt) clearTimeout(searchTimeoutt);
    setSearchTimeoutt(
      setTimeout(() => {
        dispatcher(action(value, 1, technology));
      }, 170),
    );
  }

  useEffect(() => {
    dispatch(get(1, isTechnology));
  }, [dispatch, isTechnology]);

  return (
    <Form.Item
      name={fieldName}
      style={style}
      required={required}
      label={fieldLabel}
      rules={
        required
          ? [
              {
                type: selectMode === 'multiple' ? 'array' : 'string',
                required,
                max: 30,
                message: 'A tecnologia é obrigatória',
                whitespace: true,
              },
            ]
          : []
      }
      key="select-skills-field"
    >
      <Select
        disabled={disabled}
        showSearch
        allowClear
        value={defaultValueSoluter}
        defaultValue={defaultValueSoluter}
        mode={selectMode}
        tagRender={tagRenderFunc}
        data-testid={testId}
        placeholder={placeholder}
        onSearch={value =>
          handleTecOrSkills(
            value,
            isTechnology,
            getByTitle,
            searchTimeout,
            setSearchTimeout,
            dispatch,
          )
        }
        notFoundContent={isLoadingSkills ? <Spin size="small" /> : null}
      >
        {skills?.map((skill, idx) => (
          <Option
            value={skill.title}
            key={skill.id}
            data-testid={`select-skills-option-${idx}`}
          >
            {skill.title}
          </Option>
        ))}
      </Select>
    </Form.Item>
  );
}

SelectSkills.propTypes = {
  defaultValueSoluter: PropTypes.string,
  selectMode: PropTypes.string,
  defaultValue: PropTypes.array,
  fieldName: PropTypes.string,
  fieldLabel: PropTypes.string,
  tagRenderFunc: PropTypes.func,
  placeholder: PropTypes.string,
  style: PropTypes.object,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  isTechnology: PropTypes.bool,
  testId: PropTypes.string,
};

SelectSkills.defaultProps = {
  defaultValueSoluter: '',
  selectMode: null,
  defaultValue: null,
  fieldName: 'skills',
  fieldLabel: 'Tecnologias',
  placeholder: 'Buscar tecnologia (ex.: Android)',
  tagRenderFunc: () => {
    /* skip */
  },
  style: { width: '100%' },
  required: false,
  disabled: false,
  isTechnology: false,
  testId: 'select-skills-input',
};
