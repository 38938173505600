import React, { useMemo, useState, useCallback } from 'react';
import { Table, Tag, Typography } from 'antd';
import PropTypes from 'prop-types';

import { Button } from '~/components';
import EditBusinessUnitFamily from './EditBusinessUnitFamily';

const { Link } = Typography;

export default function BusinessUnitFamiliesTable({ families }) {
  const [isEditFamilyModalVisible, setEditFamilyModalVisible] = useState(false);
  const [currentFamily, setCurrentFamily] = useState();

  const handleCloseEditFamilyModal = useCallback(() => {
    setCurrentFamily();
    setEditFamilyModalVisible(false);
  }, []);

  const columns = useMemo(
    () => [
      {
        title: 'Nome da Família',
        render: family => {
          const { id, name } = family;
          return <span data-testid={`family-name-${id}`}>{name}</span>;
        },
      },
      {
        title: 'Objetivos Específicos da Família',
        render: family => {
          const { id, skills } = family;
          return (
            <>
              {skills?.map(skill => (
                <Tag
                  data-testid={`family-skill-${id}-${skill}`}
                  key={`family-skill-${id}-${skill}`}
                  color="blue"
                >
                  {skill}
                </Tag>
              ))}
            </>
          );
        },
      },
      {
        title: 'Ações',
        align: 'end',
        render: family => {
          const { id } = family;
          return (
            <Link
              key={`edit-family-${id}`}
              data-testid={`btn-edit-family-${id}`}
              onClick={() => {
                setEditFamilyModalVisible(true);
                setCurrentFamily(family);
              }}
            >
              <Button type="edit" style={{ marginRight: 12 }} />
            </Link>
          );
        },
      },
    ],
    [],
  );

  return (
    <>
      <Table
        dataSource={families}
        columns={columns}
        data-testid="business-unit-families-table"
        pagination={{
          pageSize: 5,
        }}
        rowKey="id"
      />
      <EditBusinessUnitFamily
        isVisible={isEditFamilyModalVisible}
        setVisible={handleCloseEditFamilyModal}
        family={currentFamily}
      />
    </>
  );
}

BusinessUnitFamiliesTable.propTypes = {
  families: PropTypes.arrayOf(PropTypes.object).isRequired,
};
