/* eslint-disable no-nested-ternary */
import { useEffect, useState } from 'react';
import { Button, Row, Skeleton } from 'antd';
import { BankOutlined, TeamOutlined } from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { TitlePage, Button as CustomButton, DetailsCard } from '~/components';
import DisableTowerSwitch from '../DisableTowerSwitch';

import {
  ProjectsTable,
  AddTowerForm,
  ProjectsHeader,
  TowerGeneralInfo,
  TowerCostCenter,
} from '~/container';
import ListMembers from './ListMembers';
import { setSelectedTower } from '~/store/ducks/towers';
import { getTowerById, deleteTower } from '~/store/fetchActions/fetchTowers';
import { getRole } from '~/config/environment';

import './styles.scss';

export default function TowerDetails({ towerId }) {
  const dispatch = useDispatch();
  const { tower } = useSelector(state => state.towers);
  const { isLoadingTower, isLoadingTowerPhoto } = useSelector(
    state => state.loading,
  );
  const [year, setYear] = useState(new Date().getFullYear().toString());
  const [isAscOrder, setIsAscOrder] = useState(true);
  const [orderBy, setOrderBy] = useState('name');

  // carrega dados da torre a partir do id passado
  useEffect(() => {
    dispatch(getTowerById(towerId));
  }, [dispatch, towerId]);

  // executa depois que os dados da torre são carregados
  useEffect(() => {
    if (tower?.name)
      dispatch(setSelectedTower({ value: towerId, label: tower.name }));
  }, [dispatch, tower?.active, tower.name, towerId]);

  const handleDelete = () => {
    dispatch(deleteTower(towerId));
  };

  return (
    <div>
      <TitlePage>Torre</TitlePage>
      <DetailsCard
        image={tower?.photo}
        icon={<BankOutlined />}
        title={tower?.name || ''}
        thumbLoading={isLoadingTowerPhoto || isLoadingTower}
        description={
          typeof tower?.totalMembers !== 'undefined' && (
            <>
              <TeamOutlined style={{ marginRight: 5 }} />
              {`${tower?.totalMembers} membros`}
            </>
          )
        }
        actions={
          <>
            {isLoadingTower ? (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Skeleton.Button active={isLoadingTower} />
                <Skeleton.Button
                  style={{ marginLeft: 10 }}
                  active={isLoadingTower}
                  shape="circle"
                />
                <Skeleton.Button
                  style={{ marginLeft: 10 }}
                  active={isLoadingTower}
                  shape="circle"
                />
                <Skeleton.Button
                  style={{ marginLeft: 10 }}
                  active={isLoadingTower}
                  shape="circle"
                />
              </div>
            ) : (
              <Row align="middle">
                <Link to="/towers/schedule">
                  <Button data-testid="tower-schedule-button">
                    Gerenciar Planejamento
                  </Button>
                </Link>
                <AddTowerForm
                  button={
                    <CustomButton
                      type="edit"
                      style={{ fontSize: '20px', color: '#dfa211' }}
                      tower={tower}
                      tooltipIdentifier="Torre"
                      id={tower?._id}
                      disabled={getRole() !== 'admin'}
                    />
                  }
                  tower={tower}
                  name={tower?.name}
                  towerId={towerId}
                />
                <CustomButton
                  type="delete"
                  style={{ fontSize: '20px' }}
                  link="/towers/register"
                  id={towerId}
                  title={tower?.name}
                  tooltipIdentifier="Torre"
                  handleDelete={handleDelete}
                  disabled={getRole() !== 'admin'}
                />
                <DisableTowerSwitch tower={tower} />
              </Row>
            )}
          </>
        }
        tabs={[
          {
            title: 'Informações Gerais',
            content: <TowerGeneralInfo tower={tower} />,
          },
          {
            title: 'Projetos',
            content: (
              <>
                <ProjectsHeader
                  orderBy={orderBy}
                  setOrderBy={setOrderBy}
                  isAscOrder={isAscOrder}
                  setIsAscOrder={setIsAscOrder}
                  year={year}
                  setYear={setYear}
                  tower={tower}
                  towerId={towerId}
                />
                <ProjectsTable
                  currentYear={year}
                  tower={tower}
                  towerId={towerId}
                  orderBy={orderBy}
                  isAsc={isAscOrder}
                />
              </>
            ),
          },
          {
            title: 'Membros',
            content: <ListMembers towerId={towerId} />,
          },
          {
            title: 'Centros de Custo',
            content: <TowerCostCenter tower={tower} towerId={towerId} />,
          },
        ]}
      />
    </div>
  );
}

TowerDetails.propTypes = {
  towerId: PropTypes.string.isRequired,
};
