import React from 'react';
import {
  EyeOutlined,
  EditOutlined,
  DeleteOutlined,
  PlusCircleOutlined,
  CheckOutlined,
  CloseOutlined,
} from '@ant-design/icons';
import { Tooltip, Button as AntdButton } from 'antd';
import { Link, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

import { DeleteDialog } from '~/components';

export const Button = ({
  link,
  type,
  style,
  title,
  tooltipIdentifier,
  id,
  handleDelete,
  disabled,
  isButton,
  handleClick,
  buttonStyle,
  messageText,
  tooltipTitleConfig,
}) => {
  let iconComponent;
  let tooltipTitle;
  const pathname = window.location.pathname;
  const history = useHistory();
  switch (type) {
    case 'view':
      tooltipTitle = `Ver ${
        tooltipIdentifier !== 'Item' ? tooltipIdentifier : 'Detalhes'
      }`;
      iconComponent = (
        <EyeOutlined
          style={{ ...style }}
          key="view"
          data-testid="view-button"
        />
      );
      break;
    case 'edit':
      tooltipTitle = `Editar ${tooltipIdentifier}`;
      iconComponent = (
        <EditOutlined
          style={
            disabled
              ? { ...style, color: '#ccc', cursor: 'not-allowed' }
              : { ...style, color: '#dfa211' }
          }
          key="edit"
          data-testid="edit-button"
        />
      );
      break;
    case 'delete':
      tooltipTitle = `${tooltipTitleConfig || 'Deletar'} ${tooltipIdentifier}`;
      iconComponent = (
        <DeleteOutlined
          style={
            disabled
              ? { ...style, color: '#ccc', cursor: 'not-allowed' }
              : { ...style, color: '#f21f15' }
          }
          key="delete"
          data-testid="delete-button"
        />
      );
      break;
    case 'add':
      tooltipTitle = `Adicionar ${tooltipIdentifier}`;
      iconComponent = (
        <PlusCircleOutlined
          style={
            disabled
              ? { ...style, color: '#ccc', cursor: 'not-allowed' }
              : { ...style, color: '#1890ff' }
          }
          key="add"
          data-testid="add-button"
        />
      );
      break;
    case 'accept':
      tooltipTitle = `${tooltipTitleConfig || 'Aceitar'} ${tooltipIdentifier}`;
      iconComponent = (
        <CheckOutlined
          style={{ ...style, color: 'green' }}
          key="accept"
          data-testid="accept-button"
        />
      );
      break;
    case 'remove':
      tooltipTitle = `${tooltipTitleConfig || 'Remover'} ${tooltipIdentifier}`;
      iconComponent = (
        <CloseOutlined
          style={{ ...style, color: '#f21f15' }}
          key="remove"
          data-testid="remove-button"
        />
      );
      break;
    default:
      break;
  }

  const button = (
    <Tooltip title={tooltipTitle} trigger="hover">
      {React.cloneElement(iconComponent)}
    </Tooltip>
  );
  if (type === 'delete') {
    return pathname === '/districts' ? (
      disabled && (
        <DeleteDialog
          currentKey={`delete-${id}`}
          item={{ title, id }}
          handleDelete={handleDelete}
          disabled={disabled}
          subText={messageText}
          content={
            <Tooltip title={tooltipTitle} trigger="hover">
              {React.cloneElement(iconComponent)}
            </Tooltip>
          }
        />
      )
    ) : (
      <DeleteDialog
        currentKey={`delete-${id}`}
        item={{ title, id }}
        handleDelete={value => {
          handleDelete(value);
          if (link) history.replace(link);
        }}
        disabled={disabled}
        subText={messageText}
        content={
          <Tooltip title={tooltipTitle} trigger="hover">
            {React.cloneElement(iconComponent)}
          </Tooltip>
        }
      />
    );
  }
  // eslint-disable-next-line no-nested-ternary
  return link && !disabled ? (
    <Link to={link}>{button}</Link>
  ) : isButton ? (
    <AntdButton
      style={{ padding: 0, height: 'auto', ...buttonStyle }}
      onClick={handleClick}
      type="link"
    >
      {button}
    </AntdButton>
  ) : (
    button
  );
};

Button.propTypes = {
  link: PropTypes.string,
  type: PropTypes.oneOf(['add', 'accept', 'remove', 'view', 'edit', 'delete'])
    .isRequired,
  style: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  title: PropTypes.string,
  tooltipIdentifier: PropTypes.string,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  handleDelete: PropTypes.func,
  disabled: PropTypes.bool,
  isButton: PropTypes.bool,
  handleClick: PropTypes.func,
  buttonStyle: PropTypes.object,
  messageText: PropTypes.string,
  tooltipTitleConfig: PropTypes.string,
};

Button.defaultProps = {
  link: null,
  style: null,
  title: '',
  tooltipIdentifier: 'Item',
  id: null,
  handleDelete: () => {},
  disabled: false,
  isButton: false,
  handleClick: () => {},
  buttonStyle: null,
  messageText: 'Isso removerá o item das listas de todos os usuários',
  tooltipTitleConfig: '',
};
