import React, { useCallback, useEffect, useState } from 'react';
import { Button, Form, Input, Modal, Select, Tag } from 'antd';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import { updateBusinessUnitFamilySkills } from '~/store/fetchActions/fetchJourney';
import { SelectSkills } from '~/components';
import { changeCurrentBusinessUnitSkills } from '~/store/ducks/journey';

const { Option } = Select;

export default function EditBusinessUnitFamily({
  isVisible,
  setVisible,
  family,
}) {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const handleCancel = useCallback(() => {
    setVisible(false);
    form.resetFields();
  }, [setVisible, form]);

  useEffect(() => {
    if (isVisible && family)
      form.setFieldsValue({
        ...family,
        skills: family?.skills || [],
      });
  }, [form, isVisible, family]);

  const handleOk = useCallback(() => {
    return form.validateFields().then(({ skills }) => {
      setLoading(true);
      updateBusinessUnitFamilySkills(family, skills)
        .then(() => {
          dispatch(changeCurrentBusinessUnitSkills({ family, skills }));
          setVisible(false);
          form.resetFields();
        })
        .finally(() => setLoading(false));
    });
  }, [form, setVisible, family, dispatch]);

  return (
    <Modal
      visible={isVisible}
      title="Editar Objetivos Específicos da Família"
      okText="Salvar"
      cancelText="Cancelar"
      data-testid="business-unit-edit-family-modal"
      onCancel={handleCancel}
      footer={[
        <Button key="back" onClick={handleCancel}>
          Cancelar
        </Button>,
        <Button
          data-testid="edit-bu-save-btn"
          key="submit"
          type="primary"
          loading={loading}
          onClick={handleOk}
        >
          Salvar
        </Button>,
      ]}
    >
      <Form form={form} layout="vertical" requiredMark>
        <Form.Item label="Família" name="name">
          <Input
            type="text"
            disabled
            data-testid="family-name-input"
            key="family-name-input"
          />
        </Form.Item>
        <Form.Item label="Cargos da Família" name="roles" defaultValue={[]}>
          <Select
            mode="multiple"
            label="Cargos da Família"
            key="family-roles-select"
            tagRender={({ label: { id, name } }) => (
              <Tag
                data-testid={`role-option-${id}`}
                style={{ margin: 2 }}
                closable={false}
              >
                {name}
              </Tag>
            )}
            disabled
          >
            {family?.roles?.map(({ id, name }) => (
              <Option key={`role-option-${id}-${name}`}>{name}</Option>
            ))}
          </Select>
        </Form.Item>
        <SelectSkills
          tagRenderFunc={({ label, ...props }) => (
            <Tag
              data-testid={`skill-${label}`}
              style={{ margin: 2 }}
              color="blue"
              {...props}
            >
              {label}
            </Tag>
          )}
          fieldLabel="Objetivos Específicos da Família"
          placeholder="Buscar objetivo específico (ex.: Análise de indicadores, React)"
          testId="select-family-skills"
          selectMode="multiple"
          fieldName="skills"
        />
      </Form>
    </Modal>
  );
}

EditBusinessUnitFamily.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  family: PropTypes.objectOf(PropTypes.any),
};

EditBusinessUnitFamily.defaultProps = {
  family: {},
};
