import { createAction, createReducer } from '@reduxjs/toolkit';

const INITIAL_STATE = {
  businessUnits: [],
  currentBusinessUnit: undefined,
};

export const setBusinessUnits = createAction('SET_BUSINESS_UNITS');
export const setCurrentBusinessUnit = createAction('SET_CURRENT_BUSINESS_UNIT');
export const clearCurrentBusinessUnit = createAction(
  'CLEAR_CURRENT_BUSINESS_UNIT',
);
export const changeCurrentBusinessUnitSkills = createAction(
  'CHANGE_CURRENT_BUSINESS_UNIT_SKILLS',
);

export default createReducer(INITIAL_STATE, {
  [setBusinessUnits.type]: (state, action) => ({
    ...state,
    businessUnits: action.payload,
  }),
  [setCurrentBusinessUnit.type]: (state, action) => ({
    ...state,
    currentBusinessUnit: action.payload,
  }),
  [clearCurrentBusinessUnit.type]: (state, action) => ({
    ...state,
    currentBusinessUnit: undefined,
  }),
  [changeCurrentBusinessUnitSkills.type]: (state, action) => ({
    ...state,
    currentBusinessUnit: {
      ...state.currentBusinessUnit,
      families: state.currentBusinessUnit.families.map(family =>
        family.id !== action.payload.family.id
          ? family
          : {
              ...family,
              skills: action.payload.skills,
            },
      ),
    },
  }),
});
