/* eslint-disable no-nested-ternary */
import React, { useState, useEffect, useRef } from 'react';
import { Form, Modal, Select, Spin, Divider, Tooltip, Checkbox } from 'antd';
import { useHistory, useParams } from 'react-router-dom';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import api from '~/config/httpRequest';
import * as fetchTowers from '~/store/fetchActions/fetchTowers';
import * as fetchPeople from '~/store/fetchActions/fetchPeople';
import { validationMessages } from '~/constants';
import { SelectRole, SelectSkills, SelectSearchPeople } from '~/components';
import TechnicalInfoForm from '../../Towers/TechnicalInfoForm';

const { Option } = Select;

export default function EditProjectModal({ reload, project, record }) {
  const [form] = Form.useForm(); // Conectar o formulário ao componente Form
  const formRef = useRef(null);

  const { projects } = useSelector(state => state.towers);
  const { isLoadingSoluterBasicData } = useSelector(state => state.loading);
  const dispatch = useDispatch();
  const [isPersonRegistered, setIsPersonRegistered] = useState(false);
  const [loadingManual, setloadingManual] = useState(false);
  const [isLeader, setIsLeader] = useState(false);
  const [selectedProject, setSelectedProject] = useState(null);
  const isEmailValid = personKey => {
    return personKey.includes('@solutis.com.br');
  };
  const { email } = useParams();
  const history = useHistory();

  useEffect(() => {
    if (record && project) {
      form.setFieldsValue({
        personKey: record.person,
        mainRole: record.mainRole,
        technology: record.technology || null,
        projectKey: project.name,
      });
      setIsLeader(record.isLeader);
    }
  }, [record, project, form, dispatch]);

  useEffect(() => {
    if (record?.personKey) {
      setloadingManual(false);
      api
        .get(`/soluters/users/${record?.personKey}/profile/restricted`)
        .then(async ({ data: payload }) => {
          setloadingManual(true);
          if (payload?.primaryRole) {
            setIsPersonRegistered(true);
          } else {
            setIsPersonRegistered(false);
          }
        });
    }
    // eslint-disable-next-line
  }, [record, form]);

  async function handleSubmitEdit({
    mainRole,
    technology,
    workload,
    primaryRole,
    seniority,
    primaryTech,
  }) {
    const personKey = record.id || record._id;
    if (!isPersonRegistered && isEmailValid(record.email))
      await fetchPeople.addTechnicalInfo(personKey, {
        workload,
        primaryRole,
        seniority,
        primaryTech,
      });
    try {
      await fetchTowers.updateTeamMember(project.id, personKey, {
        personKey,
        mainRole: mainRole || record.mainRole,
        isLeader,
        technology: technology || ' ',
      });
    } catch (error) {
      // eslint-disable-next-line
      console.log(error);
    }
    reset();
    reload();
  }

  const handleChange = value => {};

  const reset = () => {
    history.goBack();
    setloadingManual(false);
    // eslint-disable-next-line
    if (!project) {
      form.resetFields();
      setSelectedProject(null);
    }
    setIsLeader(false);
  };

  return (
    <Modal
      style={{ marginTop: '1rem' }}
      title="Editar Pessoa Associada ao Projeto"
      visible={loadingManual}
      onCancel={reset}
      okText="Salvar"
      cancelText="Cancelar"
      onOk={async () => {
        form
          .validateFields()
          .then(values => {
            if (project) handleSubmitEdit(values);
          })
          // eslint-disable-next-line
          .catch(console.error);
      }}
    >
      <Form
        ref={formRef}
        form={form}
        layout="vertical"
        requiredMark
        initialValues=""
        validateMessages={validationMessages.VALIDATION_MESSAGE}
      >
        <Form.Item
          name="projectKey"
          rules={[{ required: true, message: 'O projeto é obrigatório' }]}
          required
          defaultValue=""
          label="Projeto"
        >
          <Select
            disabled={project}
            defaultValue={project ? project.name : null}
            data-getNameSoluterInTableid={
              record && project
                ? 'project-name-input-edit'
                : 'project-name-input-add'
            }
            onChange={value => {
              setSelectedProject(projects?.find(x => x._id === value));
            }}
            placeholder="Selecione o projeto"
          >
            {projects &&
              projects?.map((_project, idx) => (
                <Option
                  value={_project?._id}
                  key={_project?._id}
                  data-getNameSoluterInTableid={`project-name-option-${idx}`}
                >
                  {_project?.name}
                </Option>
              ))}
          </Select>
        </Form.Item>
        <Form.Item name="personKey" label="Pessoa">
          <SelectSearchPeople
            value={record?.name}
            valueSoluter={record?.name}
            disabled
            onChange={handleChange}
          />
        </Form.Item>
        <SelectRole
          fieldName="mainRole"
          fieldLabel="Papel"
          defaultValueSoluter={record?.primaryRole}
          required
        />
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-end',
          }}
        >
          <SelectSkills
            style={{ width: '60%' }}
            required={false}
            fieldLabel="Tecnologia"
            fieldName="technology"
            defaultValueSoluter={record?.primaryTech}
          />
          <Form.Item name="isLeader" initialValue="">
            <Checkbox
              checked={isLeader}
              disabled={!selectedProject && !project}
              onChange={() => setIsLeader(_isLeader => !isLeader)}
            >
              Líder do Projeto
            </Checkbox>
          </Form.Item>
        </div>
        <Spin
          style={{ alignSelf: 'center', textAlign: 'center', width: '100%' }}
          spinning={isLoadingSoluterBasicData || false}
        >
          {record?.name && !isPersonRegistered && (
            <>
              <Divider plain>
                Informações Gerais
                <Tooltip
                  placement="right"
                  title="Dados referentes à atuação do Soluter na empresa como um todo. "
                >
                  <QuestionCircleOutlined
                    style={{
                      cursor: 'pointer',
                      marginLeft: 5,
                      color: '#454545',
                      fontSize: 14,
                    }}
                  />
                </Tooltip>
              </Divider>
              <TechnicalInfoForm form={form} />
            </>
          )}
        </Spin>
      </Form>
    </Modal>
  );
}

EditProjectModal.propTypes = {
  reload: PropTypes.func.isRequired,
  project: PropTypes.object,
  record: PropTypes.object,
};

EditProjectModal.defaultProps = {
  project: null,
  record: {},
};
