import { endLoading, startLoading } from '../ducks/loading';
import { setBusinessUnits } from '../ducks/journey';

import api from '~/config/httpRequest';

export const getBusinessUnits = () => dispatch => {
  dispatch(startLoading('isBusinessUnitsLoading'));

  api
    .get('/soluters/journey/business')
    .then(({ data }) => {
      dispatch(setBusinessUnits(data));
      dispatch(endLoading('isBusinessUnitsLoading'));
    })
    .catch(() => {
      dispatch(setBusinessUnits([]));
      dispatch(endLoading('isBusinessUnitsLoading'));
    });
};

export const updateBusinessUnitFamilySkills = (family, skills) => {
  return api.put(`/soluters/journey/business/families/${family.id}/skills`, {
    skillsList: skills,
  });
};
