import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import { message } from 'antd';
import { BaseLayout, Routers } from '~/components';
import {
  setToken,
  setExpTime,
  getExpTime,
  setIsAuthenticated,
  getRole,
} from '~/config/environment';
import { microsoftProvider } from '~/providers/microsoft';
import { setAuthorized } from '~/store/ducks/auth';
import api from '~/config/httpRequest';
import { addInterceptors } from '~/config/interceptors';
import { selectAuthState } from '~/store/selectors';
import { getPeopleProfile } from '~/store/fetchActions/fetchPeople';
import * as fetchAuth from '~/store/fetchActions/fetchAuth';
import './styles.scss';

const App = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const [timeoutActive, setTimeoutActive] = useState(false);

  const { authorized, role } = useSelector(selectAuthState);

  message.config({
    maxCount: 1,
  });

  useEffect(() => {
    async function refreshToken() {
      if (!getExpTime()) return;
      const currentTime = Math.round(new Date().getTime() / 1000);
      const timeout = (getExpTime() - currentTime - 120) * 1000;

      if (timeout > 0) {
        setTimeoutActive(true);
        setTimeout(async () => {
          try {
            const newToken = await fetchAuth.refreshToken();
            if (newToken) {
              setToken(newToken);
              setExpTime(newToken);
            }
          } catch (error) {
            // eslint-disable-next-line no-console
            console.log(error);
          }
          setTimeoutActive(false);
        }, timeout);
      }
    }

    async function authorize(code) {
      try {
        const res = await fetchAuth.getToken(code);
        if (res.status === 200 && res.data.access_token) {
          setToken(res.data.access_token);
          setExpTime(res.data.access_token);
          const auth = await fetchAuth.checkAuthentication();
          if (auth.status === 204) {
            dispatch(setAuthorized(true));
            setIsAuthenticated(true);
            dispatch(getPeopleProfile(res.data.access_token));
            history.replace('/soluters', {});
            /* if (getRole() === 'admin') {
              history.replace('/soluters', {});
            } else {
              history.replace('/towers/register', {});
            } */
          }
        }
      } catch (error) {
        history.replace('/', {});
        setExpTime('');
        setIsAuthenticated(false);
      }
    }
    const session = microsoftProvider.extractSession(location.search);

    if (session.code) {
      authorize(session.code);
    }
    if (!timeoutActive) refreshToken();
  }, [location, history, dispatch, timeoutActive]);

  // interceptor com dispatch do redux
  addInterceptors(api, dispatch, history);

  return (
    <>
      {authorized ? (
        <BaseLayout.Authenticated>
          {getRole() === 'admin' ? (
            <Routers.Authenticated />
          ) : (
            <Routers.AuthenticatedRole />
          )}
        </BaseLayout.Authenticated>
      ) : (
        <BaseLayout.Anonymous>
          <Routers.Anonymous />
        </BaseLayout.Anonymous>
      )}
    </>
  );
};
export default App;
