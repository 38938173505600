import { createReducer, createAction } from '@reduxjs/toolkit';

const INITIAL_STATE = {
  skills: [],
  skill: {},
  total: 0,
  page: 1,
  selectedSkill: -1,
  search: false,
  searchTitle: '',
};

export const add = createAction('SKILLS_ADD');
export const get = createAction('SKILLS_GET');
export const selectEdit = createAction('SKILLS_SELECT_EDIT');
export const edit = createAction('SKILLS_EDIT');
export const remove = createAction('SKILLS_REMOVE');
export const search = createAction('SKILLS_SEARCH');

export default createReducer(INITIAL_STATE, {
  [add.type]: (state, action) => ({
    ...state,
    skill: { title: action.payload },
  }),
  [get.type]: (state, action) => ({
    ...state,
    skills: action.payload.skills,
    total: action.payload.total,
    page: action.payload.page,
  }),
  [selectEdit.type]: (state, action) => ({
    ...state,
    selectedSkill: action.payload,
  }),
  [edit.type]: (state, action) => ({
    ...state,
    skill: { title: action.payload },
  }),
  [remove.type]: state => ({ ...state }),
  [search.type]: (state, action) => ({
    ...state,
    search: action.payload.search,
    searchTitle: action.payload.searchTitle,
  }),
});
