import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { ConfigProvider } from 'antd';
import ptBR from 'antd/lib/locale/pt_BR';

import App from '~/pages/app/App';
import store from '~/store';

import 'antd/dist/antd.css';
import '~/assets/style/global.css';

ReactDOM.render(
  <React.Fragment key="key">
    <Provider store={store}>
      <Suspense fallback={<div />}>
        <ConfigProvider locale={ptBR}>
          <Router>
            <App />
          </Router>
        </ConfigProvider>
      </Suspense>
    </Provider>
  </React.Fragment>,
  document.getElementById('root'),
);
