import { createAction, createReducer } from '@reduxjs/toolkit';

const INITIAL_STATE = {
  families: [],
};

export const setFamilies = createAction('SET_FAMILIES');

export default createReducer(INITIAL_STATE, {
  [setFamilies.type]: (state, action) => ({
    ...state,
    families: action.payload,
  }),
});
