/* eslint-disable no-console */
/* eslint-disable no-nested-ternary */
// !! DOCS: https://gitlab.solutis.digital/ads/passport/passport-back-office/wikis/Planejamento
import { RightOutlined, LeftOutlined, CrownFilled } from '@ant-design/icons';
import { Table, Empty, Tooltip } from 'antd';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import moment from 'moment';
import { getRole } from '~/config/environment';
import AddEditPeopleProjectForm from './AddEditPeopleProjectForm';
import TableActions from './TableActions';
import { getBarStyles } from '~/helpers/scheduler';
import SchedulerBar from './SchedulerBar';
import Allocation from '~/helpers/Allocation';
import { scheduler } from '~/constants';
import './styles.scss';

export default function CustomScheduler({
  scheduleData,
  showEditor,
  isLoadingScheduler,
  currentYear,
  towerId,
  reload,
  setYear,
  showVacationForm,
}) {
  let currentProject = '';
  let startDate = '';
  let endDate = '';
  const [isEditMemberFormVisible, setEditMemberFormVisible] = useState(false);
  const [editProject, setEditProject] = useState({});
  const [editPerson, setEditPerson] = useState({});

  const formatDate = date => {
    return new Intl.DateTimeFormat('pt-BR').format(date);
  };

  const renderAllocationByMonth = (month, record, index) => {
    if (record?.team) {
      return <div />;
    }
    let allocations = []; // Array de alocações

    if (record?.schedule?.length > 0)
      allocations = record?.schedule?.map(scheduleObject => {
        const allocation = new Allocation(scheduleObject, month, currentYear);
        return allocation;
      });

    const content = allocations?.map(currentAllocation => {
      if (
        !currentAllocation?.isMonthAllocated() || // Se a alocação não inclui esse mês
        (currentAllocation?.getEndMonth() === scheduler.DECEMBER && // ou se o mês final da alocação é dezembro
          currentAllocation?.getEndDay() <= scheduler.MAX_DAYS_IN_MONTH && // em uma data <= 30
          index === scheduler.GO_FORWARD_ARROW_INDEX) // e a coluna sendo renderizada é a seta (>)
      )
        return <></>; // Retorna vazio

      return (
        <SchedulerBar
          tooltipTitle={`${formatDate(
            currentAllocation?.startDate,
          )} - ${formatDate(currentAllocation?.endDate)} (${
            currentAllocation?.isVacation
              ? 'Férias'
              : `${currentAllocation?.amount}%`
          })`}
          currentAllocation={currentAllocation}
          customStyle={getBarStyles(currentAllocation, record?.active, index)}
          showEditor={showEditor}
          showVacationForm={showVacationForm}
          key={currentAllocation?.id + month + startDate}
          record={{
            ...record,
            projectId: currentProject?.id,
            startDate,
            endDate,
          }}
          columnIndex={index}
        />
      );
    });
    return <>{content}</>;
  };

  const columns = [
    {
      title: 'Squad',
      dataIndex: 'name',
      key: 'name',
      fixed: 'left',
      width: '255px',
      render(text, record, index) {
        const content =
          record?.personKey && getRole() === 'admin' ? (
            <Link to={`/soluters/${record?.personKey}`}>
              <p className="soluter-name">{record?.person}</p>
            </Link>
          ) : (
            <p className="no-margin">{record?.person}</p>
          );

        if (!record?.team) {
          return (
            <div className="scheduler__name-column">
              {content}
              {record?.isLeader && (
                <CrownFilled
                  className="scheduler__crown"
                  data-testid="crown-filled-antd"
                />
              )}
            </div>
          );
        }

        return (
          <Tooltip
            title={`${moment(record?.startDate).format(
              'DD/MM/YYYY',
            )} - ${moment(record?.endDate).format('DD/MM/YYYY')}`}
          >
            {record?.name}
          </Tooltip>
        );
      },
    },
    {
      title: 'Papel',
      dataIndex: 'mainRole',
      key: 'mainRole',
      fixed: 'left',
      width: '175px',
      render(text, record, index) {
        if (!record.team) {
          return `${record.mainRole} ${
            record?.technology ? ` ${record.technology}` : ''
          }`;
        }
        return text;
      },
    },
    {
      title: 'Ações',
      width: '120px',
      align: 'center',
      fixed: 'left',
      render(text, record, index) {
        if (record.team) return '';
        return (
          <div className="scheduler__table-actions">
            <TableActions
              record={{ ...record, startDate, endDate }}
              currentProject={currentProject}
              reload={reload}
              selectedTower={towerId}
              addAllocation={showEditor}
              showEditMemberForm={(project, _record) => {
                setEditProject(project);
                setEditPerson(_record);
                setEditMemberFormVisible(true);
              }}
              showVacationForm={showVacationForm}
            />
          </div>
        );
      },
    },
    {
      title: (
        <Tooltip title="Ano Anterior">
          <a
            data-testid="previous-year"
            onClick={() => {
              const nextYear = parseInt(currentYear, 10) - 1;
              setYear(nextYear.toString());
            }}
          >
            <LeftOutlined />
          </a>
        </Tooltip>
      ),
      key: 'goBack',
      width: '20px',
      dataIndex: 'goBack',
      className: 'scheduler-cell scheduler-cell--arrow',
    },
    {
      title: 'Jan',
      align: 'center',
      dataIndex: 'Jan',
      key: 'Jan',
      width: '150px',
      className: 'scheduler-cell',
      render(text, record, index) {
        return renderAllocationByMonth('Jan', record);
      },
    },
    {
      title: 'Fev',
      align: 'center',
      dataIndex: 'Fev',
      key: 'Fev',
      width: '150px',
      className: 'scheduler-cell',
      render(text, record, index) {
        return renderAllocationByMonth('Fev', record);
      },
    },
    {
      title: 'Mar',
      align: 'center',
      dataIndex: 'Mar',
      key: 'Mar',
      width: '150px',
      className: 'scheduler-cell',
      render(text, record, index) {
        return renderAllocationByMonth('Mar', record);
      },
    },
    {
      title: 'Abr',
      align: 'center',
      dataIndex: 'Abr',
      key: 'Abr',
      width: '150px',
      className: 'scheduler-cell',
      render(text, record, index) {
        return renderAllocationByMonth('Abr', record);
      },
    },
    {
      title: 'Mai',
      align: 'center',
      dataIndex: 'Mai',
      key: 'Mai',
      width: '150px',
      className: 'scheduler-cell',
      render(text, record, index) {
        return renderAllocationByMonth('Mai', record);
      },
    },
    {
      title: 'Jun',
      align: 'center',
      dataIndex: 'Jun',
      key: 'Jun',
      width: '150px',
      className: 'scheduler-cell',
      render(text, record, index) {
        return renderAllocationByMonth('Jun', record);
      },
    },
    {
      title: 'Jul',
      align: 'center',
      dataIndex: 'Jul',
      key: 'Jul',
      width: '150px',
      className: 'scheduler-cell',
      render(text, record, index) {
        return renderAllocationByMonth('Jul', record);
      },
    },
    {
      title: 'Ago',
      align: 'center',
      dataIndex: 'Ago',
      key: 'Ago',
      width: '150px',
      className: 'scheduler-cell',
      render(text, record, index) {
        return renderAllocationByMonth('Ago', record);
      },
    },
    {
      title: 'Set',
      align: 'center',
      dataIndex: 'Set',
      key: 'Set',
      width: '150px',
      className: 'scheduler-cell',
      render(text, record, index) {
        return renderAllocationByMonth('Set', record);
      },
    },
    {
      title: 'Out',
      align: 'center',
      dataIndex: 'Out',
      key: 'Out',
      width: '150px',
      className: 'scheduler-cell',
      render(text, record, index) {
        return renderAllocationByMonth('Out', record);
      },
    },
    {
      title: 'Nov',
      align: 'center',
      dataIndex: 'Nov',
      key: 'Nov',
      width: '150px',
      className: 'scheduler-cell',
      render(text, record, index) {
        return renderAllocationByMonth('Nov', record);
      },
    },
    {
      title: 'Dez',
      align: 'center',
      dataIndex: 'Dez',
      key: 'Dez',
      width: '150px',
      className: 'scheduler-cell',
      render(text, record, index) {
        return renderAllocationByMonth('Dez', record);
      },
    },
    {
      title: (
        <Tooltip title="Ano Seguinte">
          <a
            data-testid="next-year"
            onClick={() => {
              const nextYear = parseInt(currentYear, 10) + 1;
              setYear(nextYear.toString());
            }}
          >
            <RightOutlined />
          </a>
        </Tooltip>
      ),
      key: 'goForward',
      width: '30px',
      dataIndex: 'goForward',
      className: 'scheduler-cell scheduler-cell--arrow',
      render(text, record, index) {
        return renderAllocationByMonth(
          'Dez',
          record,
          scheduler.GO_FORWARD_ARROW_INDEX,
        );
      },
    },
  ];

  return (
    <>
      <Table
        columns={columns}
        indentSize={1}
        sticky
        locale={{
          emptyText: (
            <>
              <Empty
                description="Nenhum projeto registrado (ainda)."
                image={Empty.PRESENTED_IMAGE_SIMPLE}
              />
            </>
          ),
        }}
        expandable={{ childrenColumnName: 'team' }}
        className="custom-scheduler table-shadow"
        rowKey="_id"
        rowClassName={(record, index) => {
          return `custom-scheduler ${
            !('active' in record) || record.active || record.team
              ? ''
              : 'inactive-member'
          }`;
        }}
        onRow={(record, rowIndex) => {
          if (record.team) {
            currentProject = {
              name: record.name,
              id: record._id,
              team: record.team,
            };
            startDate = record.startDate;
            endDate = record.endDate;
          }
        }}
        pagination={{ hideOnSinglePage: true }}
        dataSource={scheduleData ? [...scheduleData] : []}
        scroll={{ x: 2300 }}
        loading={isLoadingScheduler}
      />
      <AddEditPeopleProjectForm
        project={editProject}
        isVisible={isEditMemberFormVisible}
        setVisible={setEditMemberFormVisible}
        record={editPerson}
        reload={reload}
      />
    </>
  );
}

CustomScheduler.propTypes = {
  scheduleData: PropTypes.array.isRequired,
  showEditor: PropTypes.func.isRequired,
  isLoadingScheduler: PropTypes.bool.isRequired,
  currentYear: PropTypes.string.isRequired,
  setYear: PropTypes.func.isRequired,
  towerId: PropTypes.string.isRequired,
  reload: PropTypes.func.isRequired,
  showVacationForm: PropTypes.func.isRequired,
};
