import React, { useCallback, useState } from 'react';
import { Typography } from 'antd';
import { useSelector } from 'react-redux';
import { BusinessUnitsTable, ManageBusinessUnit } from '~/container';
import { selectJourneyState } from '~/store/selectors';

const { Title } = Typography;

export default function ManageJourney() {
  const { currentBusinessUnit } = useSelector(selectJourneyState);
  return currentBusinessUnit ? (
    <ManageBusinessUnit businessUnit={currentBusinessUnit} />
  ) : (
    <>
      <Title data-testid="journey-page-title">Jornada</Title>
      <BusinessUnitsTable />
    </>
  );
}
