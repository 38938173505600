import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useMemo } from 'react';
import { Table, Typography } from 'antd';

import { selectJourneyState, selectLoadingState } from '~/store/selectors';
import { getBusinessUnits } from '~/store/fetchActions/fetchJourney';
import { Button } from '~/components';
import { setCurrentBusinessUnit } from '~/store/ducks/journey';

const { Link } = Typography;

export default function BusinessUnitsTable() {
  const { businessUnits } = useSelector(selectJourneyState);
  const { isBusinessUnitsLoading } = useSelector(selectLoadingState);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getBusinessUnits());
  }, [dispatch]);

  const columns = useMemo(
    () => [
      {
        key: 'name',
        title: 'Nome da Business Unit',
        dataIndex: 'name',
      },
      {
        title: 'Ações',
        align: 'end',
        render: businessUnit => {
          const { id } = businessUnit;
          return (
            <Link
              data-testid={`btn-edit-bu-${id}`}
              key={`btn-edit-bu-${id}`}
              onClick={() => dispatch(setCurrentBusinessUnit(businessUnit))}
            >
              <Button type="edit" style={{ marginRight: 12 }} />
            </Link>
          );
        },
      },
    ],
    [dispatch],
  );

  return (
    <Table
      dataSource={businessUnits}
      columns={columns}
      loading={isBusinessUnitsLoading}
      data-testid="business-units-table"
      rowKey="id"
    />
  );
}
