import React, { useCallback, useEffect } from 'react';
import { Button, Space, Typography } from 'antd';
import { LeftOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import BusinessUnitFamiliesTable from './BusinessUnitFamiliesTable';
import { clearCurrentBusinessUnit } from '~/store/ducks/journey';
import './styles.scss';

const { Title } = Typography;

export default function ManageBusinessUnit({ businessUnit }) {
  const { name, initials, families } = businessUnit;
  const dispatch = useDispatch();

  const handleClickGoBack = useCallback(() => {
    dispatch(clearCurrentBusinessUnit());
  }, [dispatch]);

  useEffect(() => {
    return () => handleClickGoBack();
  }, [handleClickGoBack]);

  return (
    <div
      data-testid="manage-business-unit"
      className="manage-business-unit-wrapper"
    >
      <Space>
        <Title data-testid="business-unit-name">
          <Button
            onClick={handleClickGoBack}
            data-testid="go-back-business-unit"
            type="link"
            color="black"
            shape="round"
            icon={<LeftOutlined style={{ fontSize: '25px', color: 'black' }} />}
          />
          {name}
        </Title>
        <Title data-testid="business-unit-initials" level={3} type="secondary">
          {initials}
        </Title>
      </Space>
      <BusinessUnitFamiliesTable families={families} />
    </div>
  );
}

ManageBusinessUnit.propTypes = {
  businessUnit: PropTypes.objectOf(PropTypes.any).isRequired,
};
