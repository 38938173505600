import { useEffect } from 'react';
import { List } from 'antd';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { CheckCircleOutlined, WarningOutlined } from '@ant-design/icons';
import * as fetchTowers from '~/store/fetchActions/fetchTowers';
import './styles.scss';

export default function ListMembers({ towerId }) {
  const dispatch = useDispatch();
  const { members } = useSelector(state => state.towers);

  useEffect(() => {
    dispatch(fetchTowers.getMembersById(towerId));
  }, [dispatch, towerId]);

  return (
    <div>
      {members?.length > 0 ? (
        <List
          itemLayout="horizontal"
          dataSource={members}
          pagination={{
            position: 'bottom',
            pageSize: 8,
            hideOnSinglePage: true,
          }}
          renderItem={item => (
            <List.Item>
              <List.Item.Meta
                title={
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <p className="no-margin soluter-name">{item.name}</p>
                    <div>
                      {item.allocated ? (
                        <CheckCircleOutlined
                          style={{
                            color: 'green',
                            marginLeft: 7,
                            fontSize: 16,
                          }}
                          title="Usuário alocado em projeto na torre."
                        />
                      ) : (
                        <WarningOutlined
                          style={{
                            color: '#dfa211',
                            marginLeft: 7,
                            fontSize: 16,
                          }}
                          title="Usuário não alocado em projeto na torre."
                        />
                      )}
                    </div>
                  </div>
                }
                description={item.email}
              />
            </List.Item>
          )}
        />
      ) : (
        <p>Este projeto ainda não possui membros.</p>
      )}
    </div>
  );
}

ListMembers.propTypes = {
  towerId: PropTypes.string,
};

ListMembers.defaultProps = {
  towerId: {},
};
