import {
  Card,
  Form,
  Row,
  Select,
  Checkbox,
  Button,
  Divider,
  Alert,
  Radio,
  Space,
  Input,
} from 'antd';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useState } from 'react';
import { CloseOutlined } from '@ant-design/icons';
import {
  SelectSkills,
  TagRender,
  HighPotentialBadge,
  LeaderBadge,
  SelectRole,
} from '~/components';
import {
  setAdvancedSearchFilters,
  clearAdvancedSearchFilters,
} from '~/store/ducks/people';
import './styles.scss';
import { people } from '~/constants';

const { Option } = Select;

export default function AdvancedSearch({ style, setAdvancedSearchVisible }) {
  const dispatch = useDispatch();
  const {
    skills,
    seniorities,
    skillsOperator,
    leader,
    highPotential,
    onlyDismissed,
    competence,
    haveCompetence,
    primaryTech,
    primaryRole,
    notAllocated,
  } = useSelector(state => state.people.advancedSearch);

  const [form] = Form.useForm();
  const [checkAll, setCheckAll] = useState(seniorities?.length === 0);
  const seniorityOptions = ['Não se aplica', 'SR', 'PL', 'JR', 'Trainee'];
  const [isonlyDismissedActive, setonlyDismissedActive] = useState(
    onlyDismissed,
  );
  const tecnologia = true;

  const handleClear = () => {
    dispatch(clearAdvancedSearchFilters());
    form.resetFields();
    form.setFieldsValue({
      seniorities: [],
      competence: '',
      haveCompetence: false,
      primaryRole: '',
      primaryTech: '',
      notAllocated: false,
    });
    setCheckAll(true);
  };

  const handleSubmitSearch = values => {
    dispatch(setAdvancedSearchFilters(values));
    setAdvancedSearchVisible(false);
  };

  return (
    <Card
      size="small"
      title="Busca Avançada"
      extra={
        <Button
          size="small"
          onClick={() => setAdvancedSearchVisible(false)}
          type="link"
          icon={<CloseOutlined />}
          data-testid="advanced-search-close"
        />
      }
      style={{ ...style }}
    >
      <div className="advanced-search__arrow" />

      <Form onFinish={handleSubmitSearch} form={form}>
        <Row align="bottom" className="advanced-search__row mt-xs">
          <Form.Item
            name="onlyDismissed"
            initialValue={onlyDismissed}
            className="mb-0"
          >
            <Radio.Group
              onChange={({ target }) => {
                setonlyDismissedActive(target.value);

                form.setFieldsValue({
                  seniorities: [],
                  skills: [],
                  notAllocated: false,
                  onlyDismissed: target.value,
                });
              }}
              defaultChecked={onlyDismissed}
            >
              <Space direction="horizontal">
                <Radio value={false}>Soluters Ativos</Radio>
                <Radio data-testid="only-fired-checkbox" value>
                  Soluters Desligados
                </Radio>
              </Space>
            </Radio.Group>
          </Form.Item>
          {isonlyDismissedActive && (
            <Alert
              message="Obs.: Não é possível filtrar Soluters desligados por habilidade, senioridade ou sem alocação em torre"
              closable
            />
          )}
        </Row>
        <Divider style={{ marginTop: 16 }} />
        <Row align="middle" style={{ marginTop: 8 }}>
          <p className="mb-0">Possuem </p>
          <Form.Item
            name="skillsOperator"
            style={{ marginBottom: 0, marginRight: 8 }}
          >
            <Select
              disabled={isonlyDismissedActive}
              defaultValue={skillsOperator}
              className="advanced-search__select-operator"
              data-testid="select-skill-operator"
            >
              <Option data-testid="select-option-or" value="or">
                pelo menos uma destas
              </Option>
              <Option data-testid="select-option-and" value="and">
                todas estas
              </Option>
            </Select>
          </Form.Item>
          <p style={{ marginBottom: 0, marginLeft: 8, marginRight: 8 }}>
            Habilidades/experiências:
          </p>
          <SelectSkills
            defaultValueSoluter={skills || []}
            isTechnology={tecnologia}
            selectMode="multiple"
            tagRenderFunc={props => TagRender(props)}
            fieldLabel=""
            style={{
              minWidth: '35%',
              maxWidth: '45%',
              marginBottom: 0,
              paddingRight: 0,
            }}
            disabled={isonlyDismissedActive}
          />
        </Row>
        <Row align="middle" style={{ marginTop: 8 }}>
          <SelectRole
            defaultValueSoluter={primaryRole || ''}
            fieldName="primaryRole"
            fieldLabel="Papel Principal"
            required={false}
            className="advanced-search__select-operator"
            style={{
              minWidth: '40%',
              maxWidth: '40%',
              marginBottom: 0,
              paddingRight: 0,
            }}
          />

          <p style={{ marginBottom: 0, marginLeft: 8, marginRight: 8 }}>
            Tecnologia Primária:
          </p>
          <SelectSkills
            defaultValueSoluter={primaryTech || []}
            isTechnology={tecnologia}
            selectMode="multiple"
            tagRenderFunc={props => TagRender(props)}
            fieldName="primaryTech"
            required={false}
            fieldLabel=""
            style={{
              minWidth: '35%',
              maxWidth: '45%',
              marginBottom: 0,
              paddingRight: 0,
            }}
          />
        </Row>
        <Row align="middle" className="advanced-search__row">
          <p>Possuem uma destas senioridades:</p>
          <Checkbox
            onChange={e => {
              setCheckAll(e.target.checked);
              form.setFieldsValue({ seniorities: [] });
            }}
            defaultChecked={checkAll && seniorities?.length === 0}
            className="advanced-search__checkbox"
            data-testid="check-all"
            disabled={isonlyDismissedActive}
            checked={checkAll}
          >
            Todas
          </Checkbox>
          <Divider type="vertical" />
          <Form.Item
            name="seniorities"
            style={{
              marginBottom: 0,
            }}
            initialValue={seniorities}
            data-testid="seniorities-checkbox"
          >
            <Checkbox.Group
              options={seniorityOptions}
              disabled={checkAll || isonlyDismissedActive}
            />
          </Form.Item>
        </Row>
        <Row align="middle" className="advanced-search__row">
          <div className="centered-div mr-md">
            <Form.Item
              initialValue={notAllocated}
              name="notAllocated"
              className="mb-0"
              label="Apenas Soluters sem alocação vigente em torre:"
            >
              <Radio.Group
                onChange={({ target }) => {
                  form.setFieldsValue({ notAllocated: target.value });
                }}
                defaultChecked={notAllocated}
                disabled={isonlyDismissedActive}
              >
                <Space direction="horizontal">
                  <Radio value>Sim</Radio>
                  <Radio value={false}>Não</Radio>
                </Space>
              </Radio.Group>
            </Form.Item>
          </div>
        </Row>
        <Row align="middle" className="advanced-search__row">
          <p>Pertencem a esta(s) categoria(s): </p>
          <div className="centered-div mr-md">
            <Form.Item name="leader" initialValue={leader} className="mb-0">
              <Checkbox
                defaultChecked={leader}
                onChange={({ target }) =>
                  form.setFieldsValue({ leader: target.checked || '' })
                }
                data-testid="leader-checkbox"
              >
                {people.categories.LEADER}
              </Checkbox>
            </Form.Item>
            <LeaderBadge
              active
              size={20}
              iconStyles={{ fontSize: 20, marginTop: 8 }}
              additionalClassNames="mb-0"
            />
          </div>
          <div className="centered-div">
            <Form.Item
              name="highPotential"
              initialValue={highPotential}
              className="mb-0"
            >
              <Checkbox
                defaultChecked={highPotential}
                onChange={({ target }) =>
                  form.setFieldsValue({ highPotential: target.checked || '' })
                }
                data-testid="high-potential-checkbox"
              >
                {people.categories.HIGH_POTENTIAL}
              </Checkbox>
            </Form.Item>
            <HighPotentialBadge active size={20} additionalClassNames="mb-0" />
          </div>
        </Row>
        <Row align="middle" className="advanced-search__row">
          <div className="centered-div mr-md">
            <Form.Item
              initialValue={competence}
              name="competence"
              className="mb-0"
              label="Possuem a certificação"
            >
              <Input
                placeholder="Título da certificação"
                aria-label="competence"
                allowClear
                style={{ width: '290px' }}
              />
            </Form.Item>
          </div>
        </Row>
        <Row align="middle" className="advanced-search__row">
          <div className="centered-div mr-md">
            <Form.Item
              initialValue={haveCompetence}
              name="haveCompetence"
              className="mb-0"
              label="Apenas Soluters com certificações cadastradas"
            >
              <Radio.Group
                onChange={({ target }) => {
                  form.setFieldsValue({ haveCompetence: target.value });
                }}
                defaultChecked={haveCompetence}
              >
                <Space direction="horizontal">
                  <Radio value>Sim</Radio>
                  <Radio value={false}>Não</Radio>
                </Space>
              </Radio.Group>
            </Form.Item>
          </div>
        </Row>
        <Row justify="end">
          <Button
            style={{ marginRight: 8 }}
            onClick={handleClear}
            data-testid="advanced-search-clear-button"
          >
            Limpar
          </Button>
          <Button
            type="primary"
            htmlType="Submit"
            data-testid="advanced-search-submit-button"
          >
            Buscar
          </Button>
        </Row>
      </Form>
    </Card>
  );
}

AdvancedSearch.propTypes = {
  style: PropTypes.object,
  setAdvancedSearchVisible: PropTypes.func.isRequired,
};

AdvancedSearch.defaultProps = {
  style: {},
};
