/* eslint-disable no-nested-ternary */
/* eslint-disable no-console */
import { Form, Modal, Select, Spin, Divider, Tooltip, Checkbox } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import * as fetchTowers from '~/store/fetchActions/fetchTowers';
import * as fetchPeople from '~/store/fetchActions/fetchPeople';
import { clearSoluter } from '~/store/ducks/people';
import { validationMessages } from '~/constants';
import { SelectSearchPeople, SelectRole, SelectSkills } from '~/components';
import TechnicalInfoForm from '../TechnicalInfoForm';
import { getRole } from '~/config/environment';

const { Option } = Select;
const { confirm } = Modal;

export default function AddEditPeopleProjectForm({
  isVisible,
  setVisible,
  towerId,
  reload,
  project,
  record,
}) {
  const { projects } = useSelector(state => state.towers);
  const { soluter } = useSelector(state => state.people);
  const { isLoadingSoluterBasicData } = useSelector(state => state.loading);
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [isPersonRegistered, setIsPersonRegistered] = useState(false);
  const [isLeader, setIsLeader] = useState(false);
  const [selectedProject, setSelectedProject] = useState(null);

  const isEmailValid = personKey => {
    return personKey.includes('@solutis.com.br');
  };

  useEffect(() => {
    if (towerId) {
      dispatch(fetchTowers.getTowerProjects(towerId, false));
    }
    if (record && project) {
      form.setFieldsValue({
        personKey: record.personKey,
        mainRole: record.mainRole,
        technology: record.technology || null,
        projectKey: project.name,
      });
      dispatch(fetchTowers.getPeopleTower(record.person));
      setIsLeader(record.isLeader);
    }
  }, [towerId, dispatch, record, project, form]);

  useEffect(() => {
    if (soluter?.primaryRole) {
      setIsPersonRegistered(true);
      if (form.getFieldsValue().personKey && (!record || !project)) {
        form.setFieldsValue({
          mainRole: soluter.primaryRole,
          technology: soluter.primaryTech,
        });
      }
    } else setIsPersonRegistered(false);
  }, [soluter, form, project, record]);

  useEffect(() => {
    if (!project && !record) {
      // eslint-disable-next-line
      dispatch(clearSoluter());
      dispatch(fetchTowers.getPeopleTower());
    }
    // eslint-disable-next-line
  }, [dispatch, project, record]);

  useEffect(() => {
    if (record?.personKey)
      // eslint-disable-next-line
    dispatch(fetchPeople.getSoluterBasicData(record.personKey, true, false));
    // eslint-disable-next-line
  }, [dispatch, record?.personKey]);

  async function handleSubmitAdd({
    projectKey,
    mainRole,
    technology,
    personKey,
    workload,
    primaryRole,
    seniority,
    primaryTech,
  }) {
    if (!isPersonRegistered && personKey)
      try {
        await fetchPeople.addTechnicalInfo(personKey, {
          workload,
          primaryRole,
          seniority,
          primaryTech,
        });
      } catch (error) {
        console.log(error);
      }
    try {
      await fetchTowers.createTeamMember(projectKey, {
        mainRole,
        personKey,
        isLeader,
        technology,
      });
    } catch (error) {
      console.log(error);
    }
    reset();
    reload();
  }

  async function handleSubmitEdit({
    mainRole,
    personKey,
    technology,
    workload,
    primaryRole,
    seniority,
    primaryTech,
  }) {
    if (
      !isPersonRegistered &&
      isEmailValid(personKey) &&
      personKey !== record.personKey
    )
      await fetchPeople.addTechnicalInfo(personKey, {
        workload,
        primaryRole,
        seniority,
        primaryTech,
      });
    try {
      const personId =
        personKey !== '' && !isEmailValid(personKey)
          ? record.personKey && isEmailValid(record?.personKey)
            ? record?.personKey
            : ''
          : personKey;
      await fetchTowers.updateTeamMember(project.id, record._id, {
        personKey: personId,
        mainRole: mainRole || record.mainRole,
        isLeader,
        technology: technology || ' ',
      });
    } catch (error) {
      console.log(error);
    }
    reset();
    reload();
  }

  const handleChange = value => {
    // eslint-disable-next-line
    dispatch(clearSoluter());
    if (value) dispatch(fetchPeople.getSoluterBasicData(value, true, false));
    else {
      form.setFieldsValue({
        personKey: '',
      });
    }
    if (!project) {
      form.setFieldsValue({
        primaryRole: null,
        seniority: '',
        workload: '',
        primaryTech: null,
        mainRole: null,
        technology: null,
      });
    }
  };

  const reset = () => {
    setVisible(false);
    // eslint-disable-next-line
    dispatch(clearSoluter());
    if (!project) {
      dispatch(fetchTowers.getPeopleTower());
      setIsPersonRegistered(false);
      form.resetFields();
      setSelectedProject(null);
    }
    setIsLeader(false);
  };

  return (
    <Modal
      title={
        project
          ? 'Editar Pessoa Associada ao Projeto'
          : 'Adicionar Pessoa ao Projeto'
      }
      visible={isVisible}
      onCancel={reset}
      okText="Salvar"
      cancelText="Cancelar"
      /* okButtonProps={{ disabled: handleDisabled() }} */
      onOk={async () => {
        form
          .validateFields()
          .then(values => {
            if (project) handleSubmitEdit(values);
            else handleSubmitAdd(values);
          })
          .catch(console.error);
      }}
    >
      <Form
        form={form}
        layout="vertical"
        requiredMark
        validateMessages={validationMessages.VALIDATION_MESSAGE}
      >
        <Form.Item
          name="projectKey"
          rules={[{ required: true, message: 'O projeto é obrigatório' }]}
          required
          label="Projeto"
        >
          {projects?.length > 0 && (
            <Select
              disabled={project}
              defaultValue={project ? project.name : null}
              data-testid={
                record && project
                  ? 'project-name-input-edit'
                  : 'project-name-input-add'
              }
              onChange={value => {
                setSelectedProject(projects?.find(x => x._id === value));
              }}
              placeholder="Selecione o projeto"
            >
              {projects?.map((_project, idx) => (
                <Option
                  value={_project?._id}
                  key={_project?._id}
                  data-testid={`project-name-option-${idx}`}
                >
                  {_project?.name}
                </Option>
              ))}
            </Select>
          )}
        </Form.Item>
        <Form.Item name="personKey" label="Pessoa">
          <SelectSearchPeople
            value={record ? record.personKey : null}
            onChange={handleChange}
            testId={
              record && project
                ? 'select-search-people-edit'
                : 'select-search-people-add'
            }
          />
        </Form.Item>
        <SelectRole
          fieldName="mainRole"
          fieldLabel="Papel"
          required
          testId={record && project ? 'select-role-edit' : 'select-role-add'}
        />
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-end',
          }}
        >
          <SelectSkills
            style={{ width: '60%' }}
            required={false}
            fieldLabel="Tecnologia"
            fieldName="technology"
            testId={
              record && project
                ? 'select-skills-input-edit'
                : 'select-skills-input-add'
            }
          />
          <Form.Item name="isLeader">
            <Checkbox
              checked={isLeader}
              data-testid={
                record && project
                  ? 'isLeader-checkbox-edit'
                  : 'isLeader-checkbox-add'
              }
              disabled={!selectedProject && !project}
              onChange={() => setIsLeader(_isLeader => !_isLeader)}
            >
              Líder do Projeto
            </Checkbox>
          </Form.Item>
        </div>

        <Spin
          style={{ alignSelf: 'center', textAlign: 'center', width: '100%' }}
          spinning={isLoadingSoluterBasicData || false}
        >
          {soluter?.name && !isPersonRegistered && (
            /*  (getRole() !== 'admin' ? (
              <Alert
                message="Soluter com dados gerais não cadastrados, por favor contacte
                um administrador para cadastrá-lo"
                type="confirm"
              />
            ) : */ <>
              <Divider plain>
                Informações Gerais
                <Tooltip
                  placement="right"
                  title="Dados referentes à atuação do Soluter na empresa como um todo. "
                >
                  <QuestionCircleOutlined
                    style={{
                      cursor: 'pointer',
                      marginLeft: 5,
                      color: '#454545',
                      fontSize: 14,
                    }}
                  />
                </Tooltip>
              </Divider>
              <TechnicalInfoForm form={form} />
            </>
          )}
        </Spin>
      </Form>
    </Modal>
  );
}

AddEditPeopleProjectForm.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  towerId: PropTypes.string,
  reload: PropTypes.func.isRequired,
  project: PropTypes.object,
  record: PropTypes.object,
};

AddEditPeopleProjectForm.defaultProps = {
  project: null,
  record: null,
  towerId: '',
};
